/* eslint-disable no-restricted-syntax */
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import roleStoreModule from '../roleStoreModule';

export default function useRoleModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'role';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, roleStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const allChecked = ref(false);

  // UI
  const resolveCheckValue = () => {
    let temp = true;
    if (itemLocal.value.permissions) {
      for (const ability of itemLocal.value.permissions) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of ability.items) {
          if (!item.checked) {
            temp = false;
            break;
          }
        }
        if (!temp) { break; }
      }
    }
    allChecked.value = temp;
  };

  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
    resolveCheckValue();
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchRoleDetail = () => {
    isLoading.value = true;
    if (props.item.id && props.item.id > 0) {
      store.dispatch('role/getRoleDetail', props.item.id).then(response => {
        itemLocal.value = response.data;
        isLoading.value = false;
      }).catch(error => {
        toastification.showToastError(error, refForm.value);
        isLoading.value = false;
      });
    } else {
      itemLocal.value = JSON.parse(JSON.stringify(props.item));
      store.dispatch('role/getAllPermissions').then(response => {
        itemLocal.value.permissions = response.data;
        isLoading.value = false;
      }).catch(error => {
        toastification.showToastError(error, refForm.value);
        isLoading.value = false;
      });
    }
  };

  const onOpenModal = () => {
    fetchRoleDetail();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      description: itemLocal.value.description,
    };
    if (itemLocal.value.permissions) {
      const permissions = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const ability of itemLocal.value.permissions) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of ability.items) {
          if (item.checked && item.id > 0) {
            permissions.push(item.id);
          }
        }
      }

      data.permissions = permissions;
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('role/updateRole', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('role/createRole', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  watch(allChecked, val => {
    if (itemLocal.value.permissions) {
      for (const ability of itemLocal.value.permissions) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of ability.items) {
          item.checked = val;
        }
      }
    }
  });

  return {
    isLoading,
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    allChecked,
    resetItemLocal,
    resetModal,
    onSubmit,
    resolveCheckValue,
    onOpenModal,
  };
}
