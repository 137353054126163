import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import roleStoreModule from '../roleStoreModule';

export default function useRoleList() {
  const STORE_MODULE_NAME = 'role';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, roleStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    description: '',
    permissions: [],
    allChecked: false,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'role') && !vm.$can('delete', 'role');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '80px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Loại tài khoản',
      field: 'name',
    },
    {
      label: 'Số tài khoản',
      field: 'numberManagers',
    },
    {
      label: 'Mô tả',
      field: 'description',
    },

  ];
  const rows = ref([]);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const tenant = ref(null);
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchRoles = () => {
    isLoading.value = true;
    store
      .dispatch('role/fetchRoles', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteRoles = roles => {
    store
      .dispatch('role/deleteRoles', {
        ids: roles.map(_obj => _obj.id),
      })
      .then(() => {
        fetchRoles();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchRoles();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(tenant, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.tenantId = val.id;
    } else {
      delete filter.tenantId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    room,
    tenant,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchRoles,
    deleteRoles,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
  };
}
